import logo from './logo.png';
import './App.css';
import nyanCat from "./nyan_cat.json";
import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import {ApolloProvider, gql} from '@apollo/client';
import {initializeApollo} from "./apollo";

import { getAuth, signInAnonymously } from "firebase/auth";
import Home from './pages/home';
import Post from './pages/post';
import app from './firebase';
import Lottie from "lottie-react";
import {Helmet} from "react-helmet";

import ReactGA from 'react-ga';
ReactGA.initialize('G-JQG2P3T8EF');
ReactGA.pageview(window.location.pathname + window.location.search);


const runwayLogo = "https://firebasestorage.googleapis.com/v0/b/runway-prod-398404.appspot.com/o/runwayy_logo_asset.png?alt=media&token=12256499-9fb4-4eaa-8121-120eedcd07a8"

function App() {
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth(app);
    let firstOpen = true;
    auth.onAuthStateChanged((user) => {
      if (user == null) {
        signInAnonymously(auth);
      } else {
        if (firstOpen) {
          console.log("signed in onStart!!!")
        } else {
          console.log("signed in!!!")
        }
        setSignedIn(true)
      }
      firstOpen = false
    })
  });
  const metas = (
      <div>
        <Helmet>
          <title>Runwayy — Checkout Fits on Runwayy</title>
          <meta name="title" content="Runwayy — Show your fits, get inspired!" />
          <meta name="description" content="Showcase your fits and learn how people style their's." />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://teamrunway.app/" />
          <meta property="og:title" content="Runwayy — Show your fits, get inspired!" />
          <meta property="og:description" content="Showcase your fits and learn how people style their's." />
          <meta property="og:image" content={runwayLogo} />

          <meta property="twitter:card" content="runwayy_post" />
          <meta property="twitter:url" content="https://teamrunway.app/" />
          <meta property="twitter:title" content="Runwayy — Show your fits, get inspired!" />
          <meta property="twitter:description" content="Showcase your fits and learn how people style their's." />
          <meta property="twitter:image" content={runwayLogo} />
        </Helmet>
      </div>
  )
  if (signedIn) {
    return (
        <div>
          { metas }

          <ApolloProvider client={initializeApollo()}>
            <Router>
              <Routes>
                <Route exact path="/post/:id" element={<Post/>} />
                <Route path="/" element={ <Home/> } />
                <Route path="/invite" element={ <Home/> } />
                {/* Catch-all route */}
                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
            </Router>
          </ApolloProvider>
        </div>
    )
  } else {
    return (
        <div className="bg-black flex items-center justify-center h-screen">
          { metas }
          <div className="w-1/2 max-w-md">
            <Lottie animationData={nyanCat} loop={true} />
          </div>
        </div>
    )
  }
}

export default App;
