import {setContext} from "@apollo/client/link/context";
import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";

import { getAuth } from "firebase/auth";
import app from "./firebase";


const authLink = setContext(async (_, { headers, ...context }) => {
    // return the headers to the context so httpLink can read them
    const token = await idToken();
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        },
        ...context,
    }
});

const httpLink = createHttpLink({uri: process.env.REACT_APP_BACKEND_URL});

let apolloClient;

function createApolloClient() {
    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });
}

async function idToken() {
    try {
        return await getAuth(app).currentUser.getIdToken()
    } catch (e) {
        console.log(e);
        return null;
    }
}

export function initializeApollo() {
    const _apolloClient = apolloClient ?? createApolloClient();
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
}