import React, {useEffect, useState, useRef} from 'react';
import {useQuery, gql} from '@apollo/client';
import {
    useParams
} from "react-router-dom";
import Lottie from "lottie-react";
import nyanCat from "../nyan_cat.json";
import './post.css'
import {Helmet} from "react-helmet";
import {GooglePlayButton, AppStoreButton} from "react-mobile-app-button";


const POST_QUERY = gql`
    query Post($id: Int!) {
        post(id: $id) {
            id
            caption
            media {
                url
            }
            author {
                id
                handle
                photoUrl
            }
            createdAt
            likeCount
            commentCount
        }
    }
`


const APKUrl = "https://play.google.com/store/apps/details?id=com.tolu.fashion&hl=en_US";
const AppStoreUrl = "https://apps.apple.com/ca/app/runwayy/id6466562995";

function Post() {
    const {id} = useParams();
    const {loading, error, data} = useQuery(POST_QUERY, {
        variables: {"id": id}
    });
    const [width, setWidth] = useState(window.innerWidth);
    const bottomRef = useRef(null);

    useEffect(() => {
        const handleWindowSizeChange = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    useEffect(() => {
        if (data && bottomRef.current) {
            setTimeout(() => {
                bottomRef.current.scrollIntoView({behavior: 'smooth'});
            }, 100); // Small delay to ensure content is rendered
        }
    }, [data]);

    const isMobile = width <= 768;

    if (!isMobile) {
        return (
            <div
                className="flex items-center justify-center h-screen bg-black text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
                Dude, use your phone 😕
            </div>
        );
    }

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen bg-black">
                <Lottie animationData={nyanCat} loop={true} className="w-1/2 max-w-xs"/>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-screen bg-black text-white text-xl">
                Ooooof, something went wrong.
            </div>
        );
    }

    return (
        <div className="bg-black text-white min-h-screen flex flex-col justify-between">
            <Helmet>
                <title>Runwayy — Checkout this Fit on Runwayy</title>
                <meta name="title" content="Runwayy — Show your fits, get inspired!"/>
                <meta name="description" content="Showcase your fits and learn how people style their's."/>
                <meta property="og:image" content={data.post.media[0].url}/>
                <meta property="twitter:image" content={data.post.media[0].url}/>
            </Helmet>

            <div className="container mx-auto px-4 py-6 flex-1 flex flex-col justify-center">
                <div className="mb-6 rounded-lg overflow-hidden" style={{aspectRatio: '9/16'}}>
                    <img
                        src={data.post.media[0].url}
                        alt="Post content"
                        className="w-full h-full object-cover"
                    />
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-2">by {data.post.author.handle}</h2>
                    <p className="text-lg">{data.post.caption}</p>
                </div>

                <div ref={bottomRef} className="flex justify-center mt-6">
                    <div className="flex flex-row space-x-2">
                        <AppStoreButton
                            url={AppStoreUrl}
                            theme={"dark"}
                            className="w-36 sm:w-40"
                        />
                        <GooglePlayButton
                            url={APKUrl}
                            theme={"dark"}
                            className="w-36 sm:w-40"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Post;